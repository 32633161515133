function CardPortfolio(props) {

    const p = props.project;

    return <div key={props.i} className={'col-md-6 col-12 p-md-2 p-1'}>
        <div className="border- rounded-5 p-md-5 p-4 hover-div">
            <div className="row">
                <div className="col-md-7  col-12 portfolio-card-image p-0 d-none d-md-block">
                    <img src={p.image.url} alt="portfolio_image_cover_#" className={'img-fluid border shadow-sm'}/>
                </div>
                <div className="col-md-5 col-12 p-0">
                    <img src={p.image.url} alt="portfolio_image_cover_#"
                         className={'img-fluid rounded- rounded-top-4 d-md-none d-block'}/>
                    <div className="text-center pt-3 d-md-none d-block">

                        <a href={'/project/' + p.slug}
                           className={'align-items-center d-flex justify-content-around text-decoration-none'}>
                            <h2 className={'text-dark'}>{p.name.ua}</h2>
                            <br/>
                            <small className="text-secondary">
                                {p.service.name.en}
                            </small>
                        </a>
                    </div>
                    <div className="portfolio-card-info d-none d-md-block">
                        <div className="qr-tooltip top text-dark">
                            {
                                p.budget_value === 0 ? <small className="text-dark p-2">

                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 32 32" height="32" width="32"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                        <path
                                            d="M12 3L1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z"></path>
                                    </svg>

                                </small> : <small className="text-success p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                         fill="currentColor"
                                         className="bi bi-coin" viewBox="0 0 32 32">
                                        <path
                                            d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z"/>
                                        <path
                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path
                                            d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                    </svg>
                                </small>

                            }

                            <span className="tooltiptext">

                                    <svg className="w-6 h-6 text-gray-800 dark:text-white p-1" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                         viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>

                                {
                                    p.budget_value === 0 ? <small className={'text-white'}>Проект для досвіду</small>
                                        : <small className={'text-white'}>Комерційний проект</small>
                                }
 </span>



                        </div>
                        <h4 className={'text-dark'}>{p.name.ua}</h4>
                        <br/>
                        <small className="text-secondary">
                            #{p.service.name.en.toLowerCase()}
                        </small>
                    </div>
                    <div className="link-know-more p-3 pt-0 pb-0 d-none d-md-block">
                        <a href={'/portfolio/' + p.slug} className={'align-items-center d-flex text-decoration-none'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-arrow-right text-dark" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                            </svg>
                            <small className="m-2 text-dark lead">Know more</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default CardPortfolio;
