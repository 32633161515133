

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../../components/section";
import AccordionItemCustom from "../accordion";
import {Link} from "react-router-dom";

function SectionQandA(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'container'}>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5 pb-0">
                <small className="text-secondary">
                    {/*support */}
                    Q&A
                </small>

                <h2 className={'display-1'}>
                    Information
                </h2>

            </div>
        </div>

        <Section title={''} name={''} child={<div className={'rounded-5 bg-white text-white'}>

            <div className="container-fluid">
                <div className="d-flex flex-wrap justify-content-between align-items-start">

                    <div className="col-md-9 col-12 p-2 m-auto">
                        <div className="q-a"
                             // style={{marginRight: '20px'}}
                        >
                            {/*<h2 className={'text-secondary lead'}>*/}
                            {/*    Q&A*/}
                            {/*</h2>*/}
                            {/*<br/>*/}

                            <AccordionItemCustom data={[
                                {
                                    id: 0,
                                    title: "How long does the project take?",
                                    content: <div>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad asperiores delectus dignissimos ea error eum fugit harum illum itaque iure laborum, libero nam quas, qui repellat reprehenderit suscipit tenetur. Deserunt?
                                        </p>
                                        <b>Все залежить від обсягу роботи, які потрібно виконати для реалізації проекту.</b>
                                    </div>
                                },
                                {
                                    id: 1,
                                    title: "How me Tracking project status?",
                                    content: <div>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium ad aliquam aperiam autem doloremque eum iusto quas quasi ratione, sequi sunt unde ut, voluptate. Esse illum natus unde velit voluptatum?
                                        </p>
                                        <a href="/project/tracking" className={'display- text-decoration-none bg-dark text-white p-3 pt-2'}> <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                                                                                                                                                  className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                        </svg> Tracking project </a>
                                    </div>
                                },
                                {
                                    id: 2,
                                    title: "Як зрозуміти, що саме мені потрібно?",
                                    content: <div>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid error esse et excepturi hic, id inventore ipsum laboriosam maiores maxime, neque nobis pariatur quae quis sed sequi sit soluta.
                                        </p>
                                        {/*<ul>*/}
                                        {/*    <li>*/}
                                        {/*        Скористайтесь формой для співпраці*/}
                                        {/*    </li>*/}
                                        {/*    <li>*/}
                                        {/*        Зателефонуйте за номером 098 075 13 57*/}
                                        {/*    </li>*/}
                                        {/*    <li>*/}
                                        {/*        Напишіть на email: yarik@lukyanchuk.com*/}
                                        {/*    </li>*/}
                                        {/*    <li>*/}
                                        {/*        Через месенджер Whatsapp / Telegram / Viber*/}
                                        {/*    </li>*/}
                                        {/*    <li>*/}
                                        {/*        Соціальні мережі*/}
                                        {/*    </li>*/}
                                        {/*</ul>*/}
                                    </div>
                                }
                            ]} />
                        </div>
                    </div>

                </div>

                <div className="col-md-12 col-12 text-center p-5 text-dark mt-5">
                    <Link to="/info" target={'_blank'} className={'nav-link'}>More info <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                                                                     xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                                                     fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                        </svg>
                    </Link>
                </div>

            </div>

        </div>} link={'/'} linkName={''}/>

        {/*<div className="text-end p-5">*/}
        {/*    <a href="/cooperation" className={'text-decoration-none text-dark display-4'}>*/}
        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"*/}
        {/*             className="bi bi-arrow-right" viewBox="0 0 16 16">*/}
        {/*            <path fill-rule="evenodd"*/}
        {/*                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>*/}
        {/*        </svg> <b>get contact</b>*/}
        {/*    </a>*/}
        {/*</div>*/}

    </div>);
}

export default SectionQandA;
