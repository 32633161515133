

import React from 'react';
import {useTranslation} from "react-i18next";
import AccordionItemCustom from "../components/accordion";
import DataPivacyPolicy from "../data/privacy-policy";

function InfoPage(props) {

    const { t, i18n } = useTranslation()

    const data = [
        {
            title: {
                ua: "",
                en: ""
            },
            link: "",
        }
    ];

    return (<div className={'min-vh-100 pt-5 pb-5'}>


        <div className="container text-dark text-start pt-5 mt-5">
            <h1>Information</h1>

            <div className="d-flex flex-wrap mt-5 horizontal-scrollable">
                {/*<div className="item p-1 col-auto m-1">*/}
                {/*    <small className="text-secondary border rounded-5 p-2">*/}
                {/*        Text */}
                {/*    </small>*/}
                {/*</div>*/}
                <div className="p-2 border-end">
                    <a href="#questions-and-answers" className={'nav-link'}>#Questions & Answers</a>
                </div>
                <div className="p-2 border-end">
                    <a href="#contacts" className={'nav-link'}>#Contacts</a>
                </div>
                <div className="p-2 border-end">
                    <a href="#links" className={'nav-link'}>#Links</a>
                </div>
                <div className="p-2 border-end">
                    <a href="#payments" className={'nav-link'}>#Payments</a>
                </div>
                <div className="p-2 border-end">
                    <a href="#privacy-policy" className={'nav-link'}>#Privacy policy</a>
                </div>
                <div className="p-2">
                    <a href="#terms-of-use" className={'nav-link'}>#Term of use</a>
                </div>
            </div>

            <div className="content-info">
                <div className="col-12 col-md-12 m-auto">
                    <AccordionItemCustom data={[

                        {
                            id: 1,
                            title: "#contacts",
                            content: <div>
                                <p>
                                    email:  <a href="mailto:yarik@lukyanchuk.com" className={'text-decoration-none text-secondary lead'}>yarik@lukyanchuk.com</a>
                                </p>
                                <p>
                                    tel.: <a href="tel.: +380 (63) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (63) 075 13 57</a>
                                </p>

                                <p>
                                    <a href="/contacts" target={'_blank'} className={'footer-link text-decoration-none text-secondary lead display-'}>
                                        More <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                                                    className="bi bi-arrow-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                    </svg> lukyanchuk.com/contacts
                                    </a>
                                </p>
                            </div>
                        },
                        {
                            id: 2,
                            title: "#payments",
                            content: <div>
                                <h5 className={'mt-5'}>payments</h5>
                                <img src="https://benya.pro/wp-content/uploads/2024/04/image.png" alt="payments" className={'rounded-2 shadow w-100'}/>

                                <h3 className={'mt-5'}>
                                    USDT
                                </h3>
                                <small className="text-secondary">
                                    TRC20
                                </small>
                                <p>
                                    TVUAA7f5kjnVJtauKRb6GxW6DaWqkW8fbY
                                </p>
                            </div>
                        },
                        {
                            id: 3,
                            title: "#privacy-policy",
                            content: <DataPivacyPolicy />
                        },
                        {
                            id: 4,
                            title: "#terms-of-use",
                            content: <div>
                                <b>Text</b>
                            </div>
                        },
                        {
                            id: 5,
                            title: "#links",
                            content: <div>
                                <a href="/site-map" className={'text-decoration-none text-secondary nav-link'}>site map <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                                                                                             xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                                                                             fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                                </svg></a>
                            </div>
                        },
                        {
                            id:6,
                            title: "#questions-and-answers",
                            content: <div>
                                <b>Question title text ?</b>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam amet aperiam asperiores, blanditiis distinctio doloremque error exercitationem facilis inventore laboriosam magnam necessitatibus nisi nobis rem rerum similique voluptas, voluptatibus voluptatum.
                                </p>
                            </div>
                        },
                    ]} />
                </div>

            </div>

        </div>
    </div>);
}

export default InfoPage;
