import React, {useEffect, useState} from "react";
import axios from "axios";
import CardPortfolio from "../../components/card_portfolio";

function PortfolioApi(props) {

    const BLOG_API_URL = 'https://api.lukyanchuk.com/api/v1/public/data/projects?limit=6&sort=DESC';

    const [data, setData] = useState([]);

    useEffect(() => {

        // if(props.optionService !== ''){
        //     load('&service=' + props.optionService);
        // }

        load('');

    }, [])

    const load = (options) => {
        axios.get(BLOG_API_URL + options, {}).then(response => {

            setData(response.data.data)

        }).catch(e => console.log(e.message))
    }

    return (<>
        {
            props.optionService == '' ?
                data && data.map((e, i) => e.image.url !== null && <CardPortfolio project={e} i={i}/>)
                :
                data && data.map((e, i) => e.service.id == props.optionService && e.image.url !== null &&
                <CardPortfolio project={e} i={i}/>)
        }
    </>);
}

export default PortfolioApi;
