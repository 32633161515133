

import React from 'react';
import {useTranslation} from "react-i18next";

function AboutPage(props) {

    const { t, i18n } = useTranslation()

    const _photos = [
        "https://placehold.co/600x400",
        "https://placehold.co/600x400",
        "https://placehold.co/600x400",
        "https://placehold.co/600x400",
        "https://placehold.co/600x400"
    ];

    const _photos2 = [
        "https://placehold.co/600x400",
        "https://placehold.co/600x400",
        "https://placehold.co/600x400"
    ];

    return (<div className={'min-vh-100 pb-5'}>

        <div id={'photos'} className="d-flex pt-5-mt-5">
            {
                _photos.map((photo, i) =>  <div key={i} className="photo col-md-12/5">
                    <img src={photo} alt={'photo_#' + i} className={'img-fluid'} />
                </div>)
            }

        </div>

        <div id={"name"} className="col-md-6 m-auto text-center mt-5">
            <h1 className={'display-1'}>{t('my.name')}</h1>

            <p className="text-justify mt-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
            </p>

            <p className="text-justify mt-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
            </p>

            <p className="text-justify mt-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
            </p>

            <p className="text-justify mt-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
            </p>

            <p className="text-justify mt-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis illo laudantium, molestias officia quae repellat vitae! Asperiores facere ipsa maiores pariatur quia sed vero? Maiores minima officia possimus recusandae unde?
            </p>
        </div>

        <div id={'media'} className="d-flex pt-5 mt-5">
            {
                _photos2.map((photo, i) =>  <div key={i} className="photo col-md-4 text-center">
                    <img src={photo} alt={'photo_#' + i} className={'img-fluid'} />
                </div>)
            }

        </div>

        <div id={"contacts"} className="col-md-10 m-auto text-start mt-5">
            <h1 className={'display-1'}>{t('nav.contacts')}</h1>

            <div className="d-flex flex-wrap mt-5">

                <div className="col-md-6">
                    <small>email</small>
                    <p className={'display-6'}>
                        <a href="mailto:yarik@lukyanchuk.com" className={'nav-link'}>yarik@lukyanchuk.com</a>
                    </p>
                </div>

                <div className="col-md-6 text-end">
                    <small>phone</small>
                    <p className={'display-6'}>
                        <a href="tel.:_380630751357" className={'nav-link'}>+380 (63) 075 13 57</a>
                    </p>
                </div>


                <div className="col-md-6 m-auto text-center">
                    <small>link</small>
                    <p className={'display-5'}>
                        <a href="/contacts" className={'nav-link'}>more</a>
                    </p>
                </div>

            </div>
        </div>

    </div>);
}

export default AboutPage;
