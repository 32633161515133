

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'
import ButtonBackComponent from "../../components/button/back";

function PageThankYou(props) {

    const { t, i18n } = useTranslation()
    const { width, height } = useWindowSize()

    // const [load, setLoad] = useState(true)
    //
    // useEffect(() => {
    //
    //     setTimeout(() => {
    //
    //         setLoad(false)
    //
    //     }, 5000)
    //
    // }, [])

    return (<div className={'vh-100 pt-5'}>

        <Confetti
            width={width}
            height={height}
            // run={load}
            // wind={1}
            recycle={false}
            numberOfPieces={'1500'}
            onConfettiComplete={() => {
                console.log('animation done')
            }}
        />

        <div className="container text-dark text-start pt-5 mt-5">
           <div className="d-flex justify-content-between align-items-start">
               <div>
                   <h1>Thank You</h1>
                   <p className={'opacity-25 lead mt-3'}>
                       Скоро я з вам звяжуся і ми все обговоримо та реалізуємо, <b>Have a nice day!</b>
                   </p>
                   <ButtonBackComponent />
               </div>
               <div className={'col-md-5'}>
                   {/*<img src="https://images.unsplash.com/photo-1616348436168-de43ad0db179" alt="image" className={'img-fluid'}/>*/}
                   {/*<img src="https://images.unsplash.com/photo-1615486780246-76e6bb33e8b5" alt="image" className={'img-fluid'}/>*/}
                   <img src="https://images.unsplash.com/photo-1525138079-9424be9df411" alt="image" className={'img-fluid'}/>
                   {/*<img src="https://images.unsplash.com/photo-1542296030-4d54bac0fc3a" alt="image" className={'img-fluid'}/>*/}
               </div>
           </div>
        </div>
    </div>);
}

export default PageThankYou;
