import React, {useEffect} from "react";

import FooterComponent from "../footer";
import HeaderComponent from "../header";
import axios from "axios";

function PageLayouts(props) {

    const [isDarkMode, setDarkMode] = React.useState(false);

    const toggleDarkMode = (checked) => {
        setDarkMode(checked);
    };

    const detected = () => {
        try{
            axios.post('https://api.lukyanchuk.com/api/v2/public/detected', {
                'subDomain': window.location.host,
                'page':  window.location.pathname,
            }).then(res => {
                console.log(res.data)
            }).catch(e => console.log(e))

        }catch (e) {
            alert(e)
            console.log(e)
        }
    }


    useEffect(() => {
        detected();

        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDarkMode]);


    return (
      <div className="page-layout container-fluid">

          <HeaderComponent
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />

          <main className={'min-vh-100'}>

              {
                  props.child
              }

          </main>

          <FooterComponent />

          {/*<div className="bg-dark fixed-bottom col-md-2 text-white m-5 text-center d-none d-md-block">*/}

          {/*    <a href="mailto:yarik@lukyanchuk.com" target={'_blank'} className={'nav-link p-3 shadow-sm'}>*/}
          {/*        <small className="email">*/}
          {/*            /!*yarik@lukyanchuk.com*!/*/}
          {/*            Get in touch*/}
          {/*        </small>*/}
          {/*    </a>*/}
          {/*</div>*/}

      </div>
    );
};

export default PageLayouts;
