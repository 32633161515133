import React, {useState} from "react";
import Navbar from "./menu/nav";
import LanguageSwitch from "./language";
import {DarkModeSwitch} from "react-toggle-dark-mode";
import {Link} from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import {useTranslation} from "react-i18next";

function HeaderComponent (props){

    const _dateYear = new Date().getFullYear();
    const { t, i18n } = useTranslation()

    return (<header className={''}>

        <div className="shadow- border-bottom fixed-top bg-white p-4 pt-1 pb-1 d-flex justify-content-between align-items-center">
            <div className="logo d-flex justify-content-center align-items-center">
                <Navbar />
                <a href="/" className={'nav-link pt-2 pt-md-0'}>
                    <h2>Y. L.</h2>
                </a>
            </div>
            <div className="menu d-none d-md-block">
                <nav>
                    <ul className={'nav'}>
                        {/*<li className={''}>*/}
                        {/*    <a href="/" className={'nav-link text-secondary text-decoration-none'}>Home</a>*/}
                        {/*</li>*/}
                        <li className={''}>
                            <Link to={'/work'} className={'nav-link text-secondary text-decoration-none'}>
                                {/*{item.icon}*/}
                                {t('nav.work')}
                            </Link>
                        </li>
                        <li className={''}>
                            <a href="/products" className={'nav-link text-secondary text-decoration-none'}> {t('nav.products')}</a>
                        </li>
                        <li className={''}>
                            <a href="/cooperation" className={'nav-link text-secondary text-decoration-none'}> {t('nav.cooperation')}</a>
                        </li>
                        <li className={''}>
                            {/*https://yaroslav.lukyanchuk.com*/}
                            <a href="/about" target={'_blank'} className={'nav-link text-secondary text-decoration-none'}> {t('nav.about me')}</a>
                        </li>
                        <li className={''}>
                            {/*https://yaroslav.lukyanchuk.com*/}
                            {/*https://yaroslav.lukyanchuk.com/links*/}
                            <a href="/contacts" target={'_blank'} className={'nav-link text-secondary text-decoration-none'}> {t('nav.contacts')}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className={'d-flex align-items-center'}>
                {/*<a href="https://t.me/YaroslavLukyanchuk" className={'nav-link m-2'}>*/}
                {/*    <FaIcons.FaTelegram />*/}
                {/*</a>*/}
                {/*<a href="/cooperation" className={'nav-link'}>*/}
                {/*    <FaIcons.FaRegHeart color={'red'} />*/}
                {/*</a>*/}
                <div className="bg-warning text-center">
                    <small className={''}><b> v5.0</b></small>
                </div>
                <LanguageSwitch color={!props.isDarkMode ? 'white' : ''} />
                <DarkModeSwitch
                    style={{margin: '3px'}}
                    checked={props.isDarkMode}
                    onChange={props.toggleDarkMode}
                    moonColor={"grey"}
                    sunColor={"#FFBF00"}
                    size={20}
                />
            </div>

        </div>

    </header>);
}

export default HeaderComponent;
