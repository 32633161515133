

import React from 'react';
import {useTranslation} from "react-i18next";

function PageNotFound(props) {

    const { t, i18n } = useTranslation()

    return (<div id="404" className={'vh-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <h1 className={'title-section display-1'}>Ooops...</h1>

            <h5>
                404. That’s an error.
            </h5>
            <p className="lead">
                <br/>
                The requested URL / 404 was not found on this server. <br/>
                That’s all we know.
            </p>

            <div className="mt-5 col-12">

                <a href="/" className="border shadow-sm rounded-5 p-3 btn btn-dark">Go to back</a>

            </div>
        </div>

    </div>);
}

export default PageNotFound;
