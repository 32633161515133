

import React from 'react';
import {useTranslation} from "react-i18next";
import AccordionItemCustom from "../components/accordion";
import DataPivacyPolicy from "../data/privacy-policy";

function ProductsPage(props) {

    const { t, i18n } = useTranslation()

    const _data = [
        {
            name: "Random App",
            url:"https://apps.lukyanchuk.com/app/random-app",
            image: "https://office.lukyanchuk.com/storage/files/portfolio/8ihYr6s9pJUSb7B3BaAFnYzVLskerXkASPJti8aX.png"
        },
        {
            name: "Книга 1% Змін",
            url:"https://books.lukyanchuk.com/book/one-percent-changes-do-it-now",
            image: "https://books.lukyanchuk.com/image/cover/book_cover.png"
        },
        {
            name: "Блокнот продуктивності",
            url:"https://books.lukyanchuk.com/productivity-notebook/",
            image: "https://books.lukyanchuk.com/image/cover/v1-NOTEBOOK.png"
        },
        {
            name: "Курс \"БД FoxPro\"",
            url:"https://www.youtube.com/watch?v=npXoUocYrRE&list=PL6N3szFzf6LsJltveomTuF0I689KjX1-A",
            image: "https://s3-alpha.figma.com/hub/file/2729732820/69ab2bc1-7dda-4bf6-ac0b-adcb3e70b063-cover.png"
        }
    ];

    //TODO: get api
    const PRODUCTS_API_URL = 'https://api.lukyanchuk.com/api/front-end/projects?products=1&limit=10&sort=DESC';

    return (<div className={'min-vh-100'}>


        <div className="container text-dark text-start pt-5 mt-5">
            <h1 className={'p-2'}>Products</h1>


            <div className="d-flex flex-wrap">


                {
                    _data.map((product, i) => <div className="col-md-3">
                        <div className={'product-card-image p-2'}>
                            <img src={product.image} alt="" className={'img-fluid shadow-sm-hover-div border'}/>
                            <a href={product.url} target={'_blank'} className={'d-flex nav-link mt-2'}><hr className={''} style={{width: "20px"}}/><h5 className={'m-1'}>{product.name}</h5></a>
                        </div>
                    </div>)
                }


            </div>

            {/*add articles from https://yaroslav.lukyanchuk.com/files/science-work.json */}

            <a href="/" className={'nav-link opacity-25 mt-5'}>
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                </svg>
                Back
            </a>
        </div>

    </div>);
}

export default ProductsPage;
