

import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import Reaptcha from "reaptcha";

function OfferPage(props) {

    const { t, i18n } = useTranslation()

    const [name, setName] = useState('');
    const [phoneOrEmail, setPhoneOrEmail] = useState('');
    const [phoneOrEmail2, setPhoneOrEmail2] = useState('');
    const [note, setNote] = useState('');


    const [verified, setVerified] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);

    const onVerify = () => {
        setVerified(true)
    };

    const [noteError, setNoteError] = useState(false);

    const sendToTelegram = (e) => {
        e.preventDefault();

        // step 1 - local direct to thank page

        if(name || phoneOrEmail || note){

            // step 2 - api
            try{

                axios.post('https://api.lukyanchuk.com/api/v3/public/send/telegram', {
                    name,
                    phone: phoneOrEmail,
                    email: phoneOrEmail2,
                    note,
                    "g-recaptcha-response": verified
                }).then(res => {
                    setRecaptcha(false);
                    console.log(res.data)

                    // TODO: save to local storage ip and hide form

                    window.location.href = '/thank-you';

                }).catch(e => console.log(e))

            }catch (e) {
                alert(e)
                console.log(e)
            }
        }else{
            _toggleErrorNote();
        }
    }

    const _toggleErrorNote = () => setNoteError(!noteError);

    return (<div className={'vh-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <h1 className={'opacity-25'}>Cooperation</h1>
            <br/>
            {/*<h2>*/}
            {/*    Ready to build something great?*/}
            {/*</h2>*/}
            {/*<p className={'display-5'}>*/}
            {/*    Got a project in mind?*/}
            {/*</p>*/}
           <div className="d-flex align-items-end justify-content-start mt-5-">
               <div className="short-connect col-md-5 d-md-block d-none">
                   <img src="https://images.unsplash.com/photo-1579705379005-1cdcdc76f793" alt="image" className={'img-fluid w-50'}/>
               </div>
               <div className="form">
                   <h3>
                       {/*Let's work together on project*/}
                       Let me know what you are thinking of and i’ll get back to you shortly.
                   </h3>
                   <br/>
                   <form onSubmit={sendToTelegram} className={''}>
                      <div className="d-flex flex-wrap align-items-center">
                          {
                              noteError && <div className={'col-md-12 d-flex justify-content-between mb-5'}>
                                  <h5 className={'text-danger w-100 border border-danger p-2'}>
                                      Fields empty!
                                  </h5>

                                  <button onClick={_toggleErrorNote} className={'nav-link p-2 text-secondary'}>
                                      close
                                  </button>
                              </div>
                          }
                          <div className="col-md-4 p-1">
                              <input type="text" id={'name'} value={name} onChange={(e) => setName(e.target.value)} placeholder={'Name'} className={'form-control border-0 border-bottom rounded-0'}/>
                          </div>
                          <div className="col-md-4 p-1">
                              <input type="text" id={'phoneOrEmail'} value={phoneOrEmail} onChange={(e) => setPhoneOrEmail(e.target.value)} placeholder={'+1 Phone number'} className={'form-control border-0 border-bottom rounded-0'}/>
                          </div>

                          <div className="col-md-4 p-1">
                              <input type="email" id={'phoneOrEmail2'} value={phoneOrEmail2} onChange={(e) => setPhoneOrEmail2(e.target.value)} placeholder={'@Email'} className={'form-control border-0 border-bottom rounded-0'}/>
                          </div>

                          <input type="text" id={'note'} value={note} onChange={(e) => setNote(e.target.value)} placeholder={'Something about idea or project'} className={'form-control border-0 border-bottom rounded-0 mt-3 col-md-12 m-1'}/>
                      </div>
                       <div className="d-flex justify-content-between align-items-end">
                           <button type={'submit'} className="btn btn-dark p-3 rounded-pill col-12 col-md-4 mt-5 m-1">
                               Submit
                           </button>
                           <Reaptcha
                               // size="invisible"
                               ref={e => (setRecaptcha(e))}
                               sitekey="6LcnW98UAAAAAKwKipkUQedRZmocs5SmqlMzslBZ"
                               onVerify={onVerify}
                               onExpire={true}
                           />
                       </div>
                   </form>
               </div>
           </div>


            <a href="/" className={'nav-link opacity-25 mt-5'}>
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                </svg>
                Back
            </a>

        </div>


    </div>);
}

export default OfferPage;
