import React, {useState} from "react";
import {useTranslation} from "react-i18next";

function LanguageSwitch(props){

    const { t, i18n } = useTranslation()

    const [language, setLetnguage] = useState(i18n.language);

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
        setLetnguage(event.target.value);
        console.log('language: ' + event.target.value);
    }

    return (<div>
        <div id="lang-switch" content={''} className={'p-2 pt-1 pb-1'}>
            <div onChange={changeLanguage}>
                <ul className={'list-unstyled d-flex flex-column-'}>
                   {/* {*/}
                   {/*     props.color ?  <li className={'p- mt-3 m-1'}>*/}
                   {/*         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                   {/*              className="bi bi-globe text-dark" viewBox="0 0 16 16">*/}
                   {/*             <path*/}
                   {/*                 d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"/>*/}
                   {/*         </svg>*/}
                   {/*     </li>*/}
                   {/*:*/}
                   {/* <li className={'p- mt-3 m-1'}>*/}
                   {/*     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                   {/*          className="bi bi-globe text-white" viewBox="0 0 16 16">*/}
                   {/*         <path*/}
                   {/*             d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"/>*/}
                   {/*     </svg>*/}
                   {/* </li>*/}
                   {/* }*/}
                    {
                        i18n.language !== 'en' && <li className={'p- mt-3 m-1'}>
                            <label className="radio-img p-11 flag-language bg-transparent">
                                <input type="radio" value="en" name="language"/>
                                <img src="/assets/lang/images/uk.png" alt="English" width="16"/>
                                {/*<span className={'text-dark'}>Eng</span>*/}
                            </label>
                        </li>
                    }
                    {
                        i18n.language !== 'ua' && <li className={'p- mt-3 m-1'}>
                            <label className="radio-img p-11 flag-language bg-transparent">
                                <input type="radio" value="ua" name="language"/>
                                <img src="/assets/lang/images/ua.png" alt="Ukraine" width="16"/>
                                {/*<span className={props.color}>Укр</span>*/}
                            </label>
                        </li>
                    }


                </ul>
            </div>
        </div>
    </div>);
}

export default LanguageSwitch;
